import React from "react";
import {createPortal} from "react-dom";
import ExtraInfo from "./ExtraInfo";
import Section from "../../../../../../common/components/Dashboard/Section";
import CatchmentRateSelector from "./CatchmentRateSelector";
import CustomerLocationTable from "./CustomerLocationTable";
import CustomerLocationNotes from "./CustomerLocationNotes";
import {useSelectorState, useSelectorValue} from "../../../providers/selectorProvider";
import {useTogglePeriodContext} from "../Provider/context";


export default function CustomerLocation() {
    const [isFiltersOpen, setIsFiltersOpen] = useSelectorState('isFiltersOpen', false);
    const {showToggle, setShowToggle} = useTogglePeriodContext()

    React.useEffect(() => {
        setShowToggle(false)
    }, [showToggle]);

    const year = useSelectorValue('year');
    const month = useSelectorValue('month')

    return <>
        {
            document.getElementById('header-extra-info') && createPortal(
                <ExtraInfo year={year} month={month} showFilter={() => setIsFiltersOpen(true)}/>,
                document.getElementById('header-extra-info'))
        }

        <Section
            title={"Customer Location"}
            selectorsOrFilters={<>
                <CatchmentRateSelector/>
            </>}
        >
            <CustomerLocationTable/>
            <CustomerLocationNotes/>
        </Section>


    </>
}