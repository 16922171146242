import { useContext, useState } from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { Grid, Typography, Box, Card, Collapse } from '@mui/material';
import CollapsibleComponent from '../../../../common/components/CollapsibleComponent';
import GenderDemographics from '../../common/presentation/Demographics/Gender';
import AgeDemographics from '../../common/presentation/Demographics/Age';
import GenderAgeInteractionComponent from '../../common/presentation/Demographics/GenderAgeInteraction';
import { useFiltersContext, useTogglePeriodContext } from '../Provider/context';
import ToggleSelector from '../../common/presentation/selectors/toggleSelector';
import { getParamsBasedOnPeriod } from '../util';
import FilterConsent from '../Filters/FilterConsent';
import FilterChannel from '../Filters/FilterChannel';
import { useAgeGenerationDataL3, useGenderDataL3, useGenderAgeInteractionDataL3} from '../../common/presentation/Demographics/hooks';

export const DEMOGRAPHIC_TOGGLE_OPTIONS = [
    { label: 'Age', value: 'age_bin' },
    { label: 'Generation', value: 'generation_bin' },
]

export default function DemographicsChainView({ toggleGenerationAge, onToggleGenerationAgeChange }) {
    const windowSize = useContext(WindowSizeContext);
    const { filters } = useFiltersContext();
    const { period } = useTogglePeriodContext();
    const isMTD = period === 'mtd'
    const isQoQ = period === 'qoq'

    const params = {  
        month: filters?.month,  
        year: filters?.year,  
        cm_channel_desc: filters?.channel  
    }  

    if (filters.hasOwnProperty('smac_consent') && filters.smac_consent !== undefined && filters.smac_consent !== '') {
        const paramsConsentKey = `smac_cdp_consent__is_${filters.smac_consent.toString()}`  // evaluates to smac_consent__is_true or smac_consent__is_false
        params[paramsConsentKey] = 1  
    }  

    const paramsBasedOnPeriod = getParamsBasedOnPeriod(isMTD, isQoQ, params);

    const [isExpand, setExpand] = useState(true);

    const { genderData,
        isGenderDataLoading
    } = useGenderDataL3(
        {
            params: {
                ...paramsBasedOnPeriod,
                // store_id: storeID 
            }
        }
    );


    const { ageGenerationData, isAgeGenerationDataLoading}
        = useAgeGenerationDataL3(
            {
                params: {
                    ...paramsBasedOnPeriod,
                    columns:[toggleGenerationAge]
                }
            }   
        );

    const { genderAgeInteractionData,
            isGenderAgeInteractionDataLoading
        } = useGenderAgeInteractionDataL3(
            {
                params: {
                    ...paramsBasedOnPeriod,
                    columns:[toggleGenerationAge]
                }
            }
        );


    return (
        <Grid item xs={12} py={.5}>
            <Box flexGrow={1} xs={12} sx={{ backgroundColor: "#f6f6f8", borderRadius: ".5rem .5rem 0 0" }}>
                <Grid container alignItems="center" py={.5}>
                    {
                        windowSize.isOnMobile ? <>
                            <Grid item container xs={12} mx={2} display="flex" flexDirection={'column'} justifyContent={'space-between'} >
                                <Grid item xs={12} display="flex" justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography variant="h6" ml={1}>
                                        Demographics
                                    </Typography>
                                    <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                                </Grid>
                                <Grid item xs={12} my={.5} display={'flex'} justifyContent={'flex-end'}>
                                    <ToggleSelector
                                        options={DEMOGRAPHIC_TOGGLE_OPTIONS}
                                        value={toggleGenerationAge}
                                        onChange={onToggleGenerationAgeChange}
                                        additionalStyles={{ textTransform: 'none' }}
                                        lastIndexOfMain={2}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <FilterConsent />
                                </Grid>
                                <Grid item xs={12} my={.5}>
                                    <FilterChannel />
                                </Grid>
                            </Grid>
                        </> : <>
                            <Grid item xs={6} >
                                <Typography variant="h6" ml={2.5}>
                                    Demographics
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5} sm marginX={1} display='flex' justifyContent='flex-end' >
                                <FilterConsent />
                            </Grid>
                            <Grid item xs={1.5} sm marginX={1} display='flex' justifyContent='flex-end' >
                                <FilterChannel />
                            </Grid>
                            <Grid item xs={1.5} sm display='flex' justifyContent='flex-end' >
                                <ToggleSelector
                                    options={DEMOGRAPHIC_TOGGLE_OPTIONS}
                                    value={toggleGenerationAge}
                                    onChange={onToggleGenerationAgeChange}
                                    additionalStyles={{ textTransform: 'none' }}
                                    lastIndexOfMain={2}
                                />
                            </Grid>
                            <Grid item xs={.5} justifyContent={'end'}>
                                <CollapsibleComponent isExpand={isExpand} setExpand={setExpand} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={12} sx={{ mt: '-0.5rem' }}> {/* remove mt if the mt in base Demographics component is removed */}
                    <Collapse in={isExpand}>
                        <Card sx={{ p: 2, width: '100%', mt: '.5rem' }}>
                            <Grid container xs={12} sx={{ justifyContent: 'space-evenly' }} flexDirection={windowSize.isOnMobile ? 'column' : ''}>
                                <Grid item xs sm={3.5} px={.5} my={.5}>
                                    <GenderDemographics data={genderData} isLoading={isGenderDataLoading} isOnMobile={windowSize.isOnMobile} />
                                </Grid>
                                <Grid item xs sm={3.5} px={.5} my={.5}>
                                    <AgeDemographics data={ageGenerationData} genAgeValue={toggleGenerationAge} isLoading={isAgeGenerationDataLoading} isOnMobile={windowSize.isOnMobile} />
                                </Grid>
                                <Grid item xs sm={5} px={.5} my={.5}>
                                    <GenderAgeInteractionComponent data={genderAgeInteractionData} isLoading={isGenderAgeInteractionDataLoading} genAgeValue={toggleGenerationAge} isOnMobile={windowSize.isOnMobile} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Collapse>
                </Grid>
            </Grid>
        </Grid>
    );
}