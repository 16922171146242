export function getValueOrDefault(obj, key, defaultValue) {
    if (obj === undefined) {
        return defaultValue;
    }
    if (obj[key] === undefined) {
        return defaultValue;
    }
    return obj[key];
}


export function forceParseFloat(value) {
    if (value === undefined || value === null || value === '') {
        return 0;
    }
    if (typeof value === 'string') {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) {
            return 0;
        }
        return parsed;
    }
    // TODO: handle other types and ensure output is a number
    return value
}

export function titleCaseString(string) {
    const lowerCaseString = string.toLowerCase();

    const words = lowerCaseString.split(" ");
 
    return words.map((word) => { 
        // add string or word that will be all capital
        if(word === 'sm' || word === 'ncr' || word === 'bf' || word === 'cdo' || word === 'estore') {
            return word.toUpperCase()
        }
        return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");
}