import {titleCaseString} from "../../util";

export const CUSTOMER_FILTER_DEFINITIONS = {
    smac_card_type : {
        key: 'smac_card_type',
        label: 'SMAC Card Type',
        column: 'smac_card_type',
        crossFilterSources: [],
        multiSelect: false,
        labelFormatter: (label) => {
            if (!label || label === 'SMAC') return label;
            return titleCaseString(label)
        }
    },
    product_category: {
        key: 'product_category',
        label: 'Product Category',
        column: 'pm_product_category_desc',
        crossFilterSources: [],
        multiSelect: false,
        labelFormatter: (label) => {
            if (!label) return label;
            return titleCaseString(label)
        }
    },
    department: {
        key: 'department',
        label: 'Department',
        column: 'pm_department_desc_standardized',
        crossFilterSources: [
            'age_group',
            'brand',
            'subdept'
        ],
        multiSelect: false
    },
    subdept: {
        key: 'subdept',
        label: 'Sub Department',
        column: 'pm_subdept_desc_standardized',
        crossFilterSources: [
            'age_group',
            'brand',
            'department'
        ],
        multiSelect: false
    },
    brand: {
        key: 'brand',
        label: 'Brand',
        column: 'final_brand',
        crossFilterSources: [
            'age_group',
            'department',
            'subdept'
        ],
        multiSelect: true
    },
    gender_bin: {
        key: 'gender_bin',
        label: 'Gender',
        column: 'gender_bin',
        crossFilterSources: [],
        multiSelect: true,
    },
    age_group: {
        key: 'age_group',
        label: 'Age Group',
        column: 'age_bin',
        crossFilterSources: [
            'brand',
            'department',
            'subdept',
            'generation_bin',
        ],
        multiSelect: true,
        labelFormatter: (label) => {
            if (label === '65-120') {
                return '65 and Above'
            }
            return label
        }
    },
    // generation_bin: {
    //     key: 'generation_bin',
    //     label: 'Generation',
    //     column: 'generation_bin',
    //     crossFilterSources: [
    //         'age_group',
    //     ],
    //     multiSelect: true,
    // },
}


export const customerMetricDataSelector = {
    'actual_sales': row => row.pos_sku_gross_sales,
    'actual_units_sold_per_txn': row => row.pos_sku_tot_qty / row.header_tran_key,
    'actual_transactions': row => row.header_tran_key,
    'actual_basket_size': row => row.basket_size,
    'actual_frequency_of_visit': row => row.frequency_of_visit
}
export const customAgeBinOrder = [
    'Below 18',
    '18-19',
    '20-24',
    '25-29',
    '30-34',
    '35-39',
    '40-44',
    '45-49',
    '50-54',
    '55-59',
    '60-64',
    '65 and Above',
    '65-120',
    'No Data'
];