import {forceParseFloat} from "../../private/customDashboards/common/util";

export const SwitchCoordinate = (coordinate) => {
    if (Array.isArray(coordinate[1])) {
        return coordinate.map(SwitchCoordinate)
    }
    return [coordinate[1], coordinate[0]];
}

export const ConvertToReactSelectOption = (value) => {
    if (Array.isArray(value)) {
        return value.map(val => {
            return { label: val.toString(), value: val }
        })
    }

    if (typeof value === 'string') {
        return { label: value, value: value }
    }

}

export const setIndicatorBgColorClass = (val) => {
    if (val < 0) {
        return '#dc3545'
    } else if (val > 0) {
        return '#198754'
    } else {
        return '#6c757d'
    }
}

export const setPrefixForIndicator = (val) => {
    if (val < 0) {
        return val.toString().replace('-', '- ')
    }

    return `+ ${val}`
}

export const abbreviateNumber = (value, isBillionsOnly, isMillionsOnly, isThousandsOnly) => {
    if (!value) {
        return '0';
    }

    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    let formattedValue;
    if (isBillionsOnly) {
        formattedValue = (value / 1e9).toFixed(1);
        return new Intl.NumberFormat('en-US').format(formattedValue) + " B";
    }
    if (isMillionsOnly) {
        formattedValue = (value / 1e6).toFixed(1);
        return new Intl.NumberFormat('en-US').format(formattedValue) + " M";
    }
    if (isThousandsOnly) {
        formattedValue = (value / 1e3).toFixed(1);
        return new Intl.NumberFormat('en-US').format(formattedValue) + " K";
    }
    return new Intl.NumberFormat('en-US', { notation: "compact", compactDisplay: "short" }).format(value);
};


export const companyAcronymToName = acronym => {
    switch (acronym) {
        case "ssmi":
            return "Super Shopping Market, Inc."
        case "smc":
            return "Sanford Marketing Corp."
        case "svi":
            return "Super Value, Inc."
        default:
            return ""
    }
}

export const getSelectedOption = (value, options) => {
    if (Array.isArray(value)) {
        return value?.map(filter => options?.find(option => option.value === filter))
            .filter(value => !!value)
    }

    return options?.find(option => option.value === value);

}

// https://stackoverflow.com/a/64489760
export const titleCase = (s) =>
    s.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
        .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_


// https://stackoverflow.com/a/37164538
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, source) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = mergeDeep(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}


export const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length
    && Object.keys(o1).every(p => o1[p] === o2[p]);


export const arrayOfObjectsEqual = (a1, a2) => {
    if (a1.length !== a2.length) {
        return false;
    }

    return a1.every((o, i) => objectsEqual(o, a2[i]))
}

export function sortByOrder(a, b, orderArray, key) {
    var A = a[key]?.toLowerCase(), B = b[key]?.toLowerCase()
    const indexA = orderArray?.map(v => v.toLowerCase()).indexOf(A)
    const indexB = orderArray?.map(v => v.toLowerCase()).indexOf(B)

    if (indexA === -1 && indexB === -1) {
        return A > B ? 1 : -1;
    } else if (indexA === -1) {
        return 1;
    } else if (indexB === -1) {
        return -1;
    }
    if (indexA > indexB) {
        return 1;
    } else {
        return -1;
    }
}

export function sortArrayBasedOnGivenArray(array, orderArray, key) {
    if (array) {
        array.sort(function (a, b) {
            return sortByOrder(a, b, orderArray, key);
        });
        return array;
    }
}

export function sortColumnBasedOnGivenArray(rowA, rowB, orderArray, key) {
    const A = rowA[key], B = rowB[key];
    return orderArray?.indexOf(A) - orderArray?.indexOf(B);
}

export const convertToValidNumber = (val) => {
    if (val === 'N/A') {
        return val;
    }

    if (val?.toString().includes('-0.0')) {
        return 0;
    }

    if (Number.isNaN(val) || !Number.isFinite(val)) {
        return 0;
    } else {
        return val;
    }
}



export const convertNumberToLocaleString = (val) => {
    if (!val) {
        return 0
    }

    if (typeof (val) === 'string') {
        val = parseFloat(val)
    }

    return val?.toLocaleString(undefined, {
        maximumFractionDigits: 0,
    })
}


export const isObjectEqual = (obj1, obj2) => {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
        return false;
    }
    return obj1Keys.every(key => obj1[key] === obj2[key]) && obj2Keys.every(key => obj1[key] === obj2[key]);
}

export const getObjectDiff = (obj1, obj2) => {
    if (!obj2) {
        return obj1
    }
    if (!obj1) {
        return obj2
    }
    if (isObjectEqual(obj1, obj2)) {
        return {}
    }

    const result = {};
    Object.keys(obj1).forEach(key => {
        if (!obj2.hasOwnProperty(key)) {
            result[key] = obj1[key];
        } else if (obj1[key] !== obj2[key]) {
            result[key] = obj1[key];
        } else if (isObject(obj1[key]) && isObject(obj2[key])) {
            // recursion to get deeper changes
            const difference = getObjectDiff(obj1[key], obj2[key]);
            if (Object.keys(difference).length > 0) {
                result[key] = difference;
            }
        }
    })
    return result;
}

export const displayNumber = (value, isWindowSizeMobile, isThousandsOnly) => {
    const checkedValue = valueCheckerIfValid(value)

    if (isWindowSizeMobile) { // or if preference indicates numbers be abbreviated
        // Determine if the number should be abbreviated in millions only
        const isMillions = checkedValue >= 1000000;
        const isThousands = checkedValue > 1000;
        if (isThousands && isThousandsOnly) {
            return abbreviateNumber(checkedValue, false, false, true);
        }
        return abbreviateNumber(checkedValue, false, isMillions);
    } else {
        if(isThousandsOnly) {
            return abbreviateNumber(checkedValue, false, false, true);
        }
        return convertNumberToLocaleString(checkedValue);
    }
};

export const decimalDisplayNumber = (value, isWindowSizeMobile) => {
    if (typeof value === 'number') {
        return value.toFixed(3);
    } else {
        return value; // Return the value unchanged if it's not a number
    }
}

export const topLineDisplayNumber = (value, isWindowSizeMobile, isThousandsOnly) => {
    const checkedValue = valueCheckerIfValid(value)

    if (!isWindowSizeMobile) { // or if preference indicates numbers be abbreviated
        // Determine if the number should be abbreviated in millions only
        const isBillions = checkedValue >= 1000000000;
        const isMillions = checkedValue >= 1000000;
        const isThousands = checkedValue >= 1000;
        if(isThousandsOnly && isThousands) {
            return abbreviateNumber(checkedValue, false, false, true);
        }

        return abbreviateNumber(checkedValue, isBillions, isMillions, isThousands);
    } else {
        return convertNumberToLocaleString(checkedValue);
    }
};

/**
 * Replaces only the last index of searchValue with replaceValue
 * Works like replace() and replaceAll()
 */
export function replaceLast(originalString, searchValue, replaceValue) {
    const lastIndex = originalString.lastIndexOf(searchValue);
    if (lastIndex === -1) {
        // If the searchValue is not found, return the original string unchanged
        return originalString;
    }
    // Replace the last occurrence of searchValue with replaceValue
    return originalString.slice(0, lastIndex) + replaceValue + originalString.slice(lastIndex + searchValue.length);
}

export const rollupDataBy = (data, uniqueKeys, summationKeys, options={}) => {
    // TODO: move summationKeys to options
    // Rollup data by uniqueKeys and summationKeys
    // data: array of objects
    // uniqueKeys: array of keys to group by
    // summationKeys: array of keys to sum up
    // options.distinctValues: array of keys to get the distinct values
    // returns an array of objects
    //
    // sample:
    // input = [
    //    {a: 'x', b: 'y', c: 1, d: 'E},
    //    {a: 'x', b: 'y', c: 2, d: 'F'},
    //    {a: 'x', b: 'z', c: 5, d: 'G}
    //  ]
    // rollupDataBy(input, ['a', 'b'], ['c'])
    // output: [
    //    {a: 'x', b: 'y', c: 3, d: 'E'},
    //    {a: 'x', b: 'z', c: 5, d: 'G'}
    // ]
    if (!data || data.length === 0) {
        return [];
    }

    const result = data.reduce((acc, curr) => {
        const key = uniqueKeys?.map(k => curr[k]).join('-|-');  // Create a unique key for the object based on uniqueKeys

        if (!acc[key]) {
            acc[key] = {...curr};
            summationKeys?.forEach(k => acc[key][k] = 0);  // Initialize summation keys to 0
            if (options.distinctValues) {
                options.distinctValues.forEach(k => {
                    acc[key][k] = new Set();
                })
            }
        } else {
            acc[key] = {
                ...curr,
                ...acc[key]
            };
        }

        summationKeys?.forEach(k => {
            const valueToAdd = (curr[k] === undefined || curr[k] === null) ? 0 : curr[k]
            if (typeof valueToAdd === 'string') {
                acc[key][k] += forceParseFloat(valueToAdd)
            } else {
                acc[key][k] += valueToAdd
            }
        });
        if (options.distinctValues) {
            options.distinctValues.forEach(k => {
                acc[key][k].add(curr[k]);
            });
        }
        return acc;
    }, {});

    return Object.values(result);
}

export const valueCheckerIfValid = (value) => {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }
    if (!value || !isFinite(value) || isNaN(value) || value === -0.000) {
        return 0;
    } else {
        return value;
    }
};


export function getAuthTokens() {
    const localStorageTokenItem = localStorage.getItem('auth-tokens-production');
    return JSON.parse(localStorageTokenItem)
}

export function clearAuthTokens() {
    localStorage.removeItem('auth-tokens-production');
}

export function isLoggedIn() {
    return getAuthTokens()  === null ? false : true
}

/**
 * Transforms a flat array of objects into a nested dictionary structure.
 *
 * @param {Array} data - The array of objects to be transformed.
 * @param {Array} keys - The keys to be used for nesting. The order of keys in this array determines the depth of nesting.
 * @param {Array} valueKeys - The keys to be used for the values in the deepest level of the nested dictionary.
 * @param {Object} [targetObject={}] - An optional target object to which the nested dictionary will be added. If not provided, a new object will be created.
 * @returns {Object} The nested dictionary.
 *
 * @example
 * const data = [
 *   { country: 'USA', state: 'California', city: 'Los Angeles', population: 3977686 },
 *   { country: 'USA', state: 'California', city: 'San Francisco', population: 883255 },
 *   { country: 'USA', state: 'New York', city: 'New York', population: 8398748 },
 * ];
 * const keys = ['country', 'state', 'city'];
 * const valueKeys = ['population'];
 * const nestedDict = rowsToNestedDict(data, keys, valueKeys);
 * // nestedDict will be:
 * // {
 * //   USA: {
 * //     California: {
 * //       Los Angeles: { population: 3977686 },
 * //       San Francisco: { population: 883255 },
 * //     },
 * //     New York: {
 * //       New York: { population: 8398748 },
 * //     },
 * //   },
 * // }
 */
const rowsToNestedDict = (data, keys, valueKeys, targetObject = {}) => {
    // If there are no more keys, we are at the deepest level
    if (keys.length === 0) {
        if (data.length === 1) {
            // If there is only one row left, we add its values to the target object
            return valueKeys.reduce((acc, key) => {
                const val = data[0][key];
                acc[key] = val || acc[key];
                return acc;
            }, targetObject);
        } else {
            throw new Error('Multiple rows at the deepest level');
        }
    }

    // If there are still keys, we continue nesting
    const key = keys[0];
    const remainingKeys = keys.slice(1);

    // We group the data by the current key and recursively call rowsToNestedDict for each group
    return data.reduce((acc, row) => {
        const keyValue = row[key];
        if (!acc[keyValue]) {
            acc[keyValue] = rowsToNestedDict(data.filter(r => r[key] === keyValue), remainingKeys, valueKeys);
        } else {
            acc[keyValue] = {
                ...acc[keyValue],
                ...rowsToNestedDict(data.filter(r => r[key] === keyValue), remainingKeys, valueKeys, acc[keyValue]),
            };
        }
        return acc;
    }, targetObject);
};


/**
 * Joins multiple datasets into a nested dictionary structure.
 *
 * @param {Array} datasets - An array of datasets to be joined. Each dataset is an array of objects.
 * @param {Array} keys - The keys to be used for nesting. The order of keys in this array determines the depth of nesting.
 * @param {Array} valueKeys - The keys to be used for the values in the deepest level of the nested dictionary.
 * @returns {Object} The nested dictionary resulting from joining the datasets.
 *
 * @example
 * const data1 = [
 *   { country: 'USA', state: 'California', city: 'Los Angeles', population: 3977686 },
 *   { country: 'USA', state: 'California', city: 'San Francisco', population: 883255 },
 * ];
 *
 * const data2 = [
 *   { country: 'USA', state: 'California', city: 'Los Angeles', area: 564 },
 *   { country: 'USA', state: 'California', city: 'San Francisco', area: 875 },
 *   { country: 'USA', state: 'New York', city: 'New York', area: 783 },
 * ];
 *
 * const datasets = [data1, data2];
 * const keys = ['country', 'state', 'city'];
 * const valueKeys = ['population', 'area'];
 *
 * const nestedDict = joinDatasets(datasets, keys, valueKeys);
 * // nestedDict will be:
 * // {
 * //   USA: {
 * //     California: {
 * //       Los Angeles: { population: 3977686, area: 564 },
 * //       San Francisco: { population: 883255, area: 875 },
 * //     },
 * //     New York: {
 * //       New York: { area: 783 },
 * //     },
 * //   },
 * // }
 */
export const joinDatasets = (datasets, keys, valueKeys) => {
    return datasets.reduce((acc, dataset) => {
        return rowsToNestedDict(dataset, keys, valueKeys, acc)
    }, {})
}


/**
 * Transforms an object of parameters into a format suitable for API requests.
 *
 * @param {Object} params - The parameters to be transformed.
 * @returns {Object} The transformed parameters.
 *
 * @example
 * const params = {
 *   country: 'USA',
 *   cities: ['Los Angeles', 'San Francisco', 'New York'],
 * };
 *
 * const preparedParams = prepareParams(params);
 * // preparedParams will be:
 * // {
 * //   country: 'USA',
 * //   cities__in: 'Los Angeles,San Francisco,New York',
 * // }
 */
export const prepareParams = (params) => {
    if (!params) {
        return {}
    }
    return Object.keys(params).reduce((acc, key) => {
        if (Array.isArray(params[key])) {
            if (params[key].length === 0) {
                return acc;
            } else if (params[key].length === 1) {
                return {
                    ...acc,
                    [key]: params[key][0]
                }
            }

            return {
                ...acc,
                [`${key}__in`]: params[key].join(',')
            }
        }

        return {
            ...acc,
            [key]: params[key]
        }
    }, {})
}


export const isArraysEqual = (array1, array2) => {
    const array2Sorted = array2.slice().sort();
    return array1.length === array2.length && array1.slice().sort().every(function (value, index) {
        return value === array2Sorted[index];
    });
}


export const reduceDistinct = (acc, val) => {
    if (!acc.includes(val)) {
        acc.push(val)
    }
    return acc
}