import { ToggleButtonGroup, ToggleButton, Box } from '@mui/material';
import React from 'react';
import { styled } from "@mui/material/styles";


export default function ToggleSelector({value, onChange, options, additionalStyles}) {
    const NewToggleButton = styled(ToggleButton)({
        backgroundColor: '#fff',
        color: '#0A4FB4',
        borderRadius: '1.5rem',
        border: 'none',
        height: '2rem',
        '&.Mui-selected, &.Mui-selected:hover': {
            border: '1px solid #0A4FB4',
            backgroundColor: '#0A4FB4',
            color: '#fff'
        },
        '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            marginRight: '.25rem'
        },
        '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderLeft: 'none'
        },
        '&.MuiToggleButtonGroup-grouped:not(:last-of-type),&.MuiToggleButtonGroup-grouped:not(:first-of-type)':{
            borderRadius: '1.5rem',
        },
        '&.Mui-disabled, &.Mui-disabled:hover': {
            color: '#00000042',
            backgroundColor: '#fff',
            border: 'none',
            pointerEvents: 'none',
            cursor: 'not-allowed'
        }
      });
      


    return (
        <Box sx={{
            backgroundColor: '#fff',
            borderRadius: '1.5rem',
            padding: '.15rem',
            border: '1px solid #d8d8d8',
            marginRight: '.25rem'
        }}>
            <ToggleButtonGroup
                size="small"
                value={value}
                exclusive
                onChange={onChange}
                aria-label="toggle-selector"
            >
                {
                    options?.map(option => 
                        <NewToggleButton 
                            value={option.value} 
                            sx={{ ...additionalStyles, px: 2 }}
                            disabled={option.disabled || false} // If disabled is not provided, default to false
                        > 
                            {option.label} 
                        </NewToggleButton>
                    )
                }
            </ToggleButtonGroup>
        </Box>
    )
}