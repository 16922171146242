import React, {useMemo }from 'react'
import {Grid, Card, Typography} from '@mui/material'
import { abbreviateNumber } from '../../../../../common/utils';
import ReactEcharts from "echarts-for-react";
import LoadingComponent from '../../../../../common/components/LoadingComponent';


export default function GenderAgeInteractionComponent({data, genAgeValue, isOnMobile, isLoading}) {

  // Sorts the data according to the sorting order defined
  const sortedData = useMemo(() => {
    const sortingOrders = {
      age_bin: [
        'No Data',
        '65 and Above',
        '60-64',
        '55-59',
        '50-54',
        '45-49',
        '40-44',
        '35-39',
        '30-34',
        '25-29',
        '20-24',
        '18-19',
      ],
      generation_bin: [
        'No Data',
        'Boomers',
        'Gen X',
        'Millenials',
        'Gen Z',
      ]
    };

    const sortingOrder = sortingOrders[genAgeValue] || [];

    return data?.sort((a, b) => {
        const indexA = sortingOrder.indexOf(a.categoryGrouping);
        const indexB = sortingOrder.indexOf(b.categoryGrouping);

        return indexA - indexB;
    });

}, [data, genAgeValue]);

  // Male Data
  const maleData = sortedData?.map((item) => {
        const value = item?.male
      return Math.abs(value) * -1
    })

  // Female Data
  const femaleData = sortedData?.map((item) => item?.female)

  // Category group
  const categoryGroup = sortedData?.map(item => item?.categoryGrouping)

  const option = {
      animation: false,
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          let tooltipContent = '<div style="text-align:left">';
          tooltipContent += 'Age Group: ' + params[0].name + '<br/>'; 
      
          params.forEach(function(item) {
            const seriesName = item.seriesName;
            const value = Math.abs(item.value);
            tooltipContent += '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:8px;height:8px;background-color:' + item.color + '"></span>'; // Circle indicator
            tooltipContent += seriesName + ': ' + value.toLocaleString() + '<br/>';
          });
      
          tooltipContent += '</div>';
          return tooltipContent;
        }
      },
      legend: {
        data: ['Male', 'Female'],
        bottom:5,
        left: isOnMobile ? 125: 250 , 
        itemGap: 30,
      },
      grid: {
        left: '10%',
        right:'15%',
        bottom: '5%',
        top:'5%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'value',
          show:false,
        }
      ],
      yAxis: [
        {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLabel: {
            color:'black',
            fontWeight:'bold'
          },
          data: categoryGroup,
        }
      ],
      series: [
        {
          name: 'Male',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
            position: 'insideRight',
            fontWeight:'bold',
            formatter: function(params) {
              const value = Math.abs(params.value)

              if(value === 0){
                  return ''
              }

              return abbreviateNumber(value)
            }
          },
          emphasis: {
            focus: 'series'
          },
          itemStyle:{
            color:'#4e88d9',
          },
          barCategoryGap: '2%', 
          data: maleData,
        },
        {
          name: 'Female',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
            position:'insideLeft',
            fontWeight:'bold',
            formatter: function(params) {
              return abbreviateNumber(params.value)
              }
          },
          emphasis: {
            focus: 'series'
          },
          itemStyle:{
            color:'#de6466'
          },
          data: femaleData,
        }
      ]
    };

    // Stop center line from adjusting 
    const maxAbsoluteValue = data && Math.max(
      Math.max(...maleData.map(Math.abs)), // For 'Male'
      Math.max(...femaleData.map(Math.abs)) // For 'Female'
    );
    
    // Set the maximum and minimum value of the x-axis to the highest absolute value
    option.xAxis[0].max = maxAbsoluteValue;
    option.xAxis[0].min = -maxAbsoluteValue; // Set the minimum value symmetrically


    return <>
        <Card sx={{ minHeight: '25rem'}}>
                <Grid container xs={12} sm={12} sx={{ backgroundColor: '#CADDEB', padding: 1.5 }} display="flex" alignItems="center">
                    <Grid item sm={12} xs={12}>
                        <Typography variant="h6" ml={3}>{`Gender - ${genAgeValue === 'age_bin' ? 'Age' : 'Generation'} Interaction`}</Typography>
                    </Grid>
                </Grid>
                <Grid xs={12}> 
                    {(() => {
                        if (isLoading) {
                            return (
                                <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: isOnMobile ? '25rem' : '30rem' }}>
                                    <LoadingComponent />
                                </Grid>
                            );
                        } 
                        else if (data === undefined ||data?.length === 0){
                            return ( 
                                <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: isOnMobile ? '25rem' : '30rem' }}>
                                          No Available Data 
                                </Grid> 
                              )
                        }
                        else {
                            return (
                                <ReactEcharts option={option} style={{ height: isOnMobile ? '25rem' : '30rem', minWidth: isOnMobile ? '10vw' : 'none' }} />
                            );
                        }
                    })()}
                </Grid>
            </Card>
    </>
}