import React, { useContext } from 'react';
import { WindowSizeContext } from "../../../../common/context/WindowSizeContext";
import { topLineDisplayNumber, displayNumber, decimalDisplayNumber } from "../../../../common/utils";
import { Box } from '@mui/material';


export default function NumericValueDisplay({ value, prefix, postfix, isAbbreviated, undefinedDisplay = '-', isTopLine, isOnScoreCard, isThousandsOnly }) {
    const windowSize = useContext(WindowSizeContext);
    
    if (value === 'N/A') {
        if (isOnScoreCard) {
            return value;
        }
        return <Box textAlign={'center'}>
            {value}
        </Box>;
    }

    if (!value || !isFinite(value) || isNaN(value)) {
        if (isOnScoreCard) {
            return undefinedDisplay;
        }
        return <Box textAlign={'center'}>
            {undefinedDisplay}
        </Box>;
    }

    let display;

    // Added condition for negative values including -0
    if (value < 1 && Math.sign(value) !== -1) {
        display = decimalDisplayNumber(value); // Format value to 3 decimal places if less than 1
    } else if (value.toString().includes('-0.000')) {
        display = 0; // Replace negative zero with zero
    } else {
        const shouldAbbreviate = isTopLine || isAbbreviated;

        const numberDisplay = displayNumber(value,windowSize.isOnMobile, isThousandsOnly);
        const numberDisplayTop = topLineDisplayNumber(value,windowSize.isOnMobile && !shouldAbbreviate, isThousandsOnly);

        display = shouldAbbreviate ? numberDisplayTop : numberDisplay;
    }
    return <>{prefix}{display}{postfix}</>;
}
