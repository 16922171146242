import moment from "moment";
import { abbreviateNumber, convertNumberToLocaleString, convertToValidNumber, decimalDisplayNumber, displayNumber, topLineDisplayNumber } from "../../../common/utils";
import { Box } from "@mui/material";

export const monthYearToTransactionDate = (months, year) => {
    if (!months || months.length === 0 || !year) {
        return {}
    }
    // months is an array of numbers, 1=January, 2=February, etc.
    const minMonth = Math.min(...months)
    const maxMonth = Math.max(...months)

    const minDate = moment(`${year}-${minMonth.toString().padStart(2, '0')}-01`)
    const maxDate = moment(`${year}-${maxMonth.toString().padStart(2, '0')}-01`)

    // create a list of months between minDate and maxDate
    const monthsBetween = []
    let currentDate = minDate
    while (currentDate <= maxDate) {
        monthsBetween.push(currentDate.format("MMM"))
        currentDate = currentDate.add(1, 'month')
    }

    const newParams = {
        'year__in': year
    }
    newParams['month__in'] = monthsBetween.map(month => month.toUpperCase()).join(',')

    // clear the year and month parameters
    newParams['year'] = undefined
    newParams['month'] = undefined

    return newParams

}

export const convertParamsToTransactionDate = (params) => {
    return {
        ...params,
        ...monthYearToTransactionDate(params.month, params.year)
    }
}

export const monthYearToMetricDate = (months, year, dayOfMonthMaxDate) => {
    if (!months || months.length === 0 || !year) {
        return {}
    }
    // months is an array of numbers, 1=January, 2=February, etc.
    const minMonth = Math.min(...months)
    const maxMonth = Math.max(...months)

    const startDate = new Date(year, minMonth - 1, 1)  // since minMonth is 1-based, we need to subtract 1 from month to get the first day of the month
    const endDate = dayOfMonthMaxDate ?
        new Date(year, maxMonth - 1, dayOfMonthMaxDate) :  // since maxMonth is 1-based, we need to subtract 1 from month to get the last day of the month
        new Date(year, maxMonth, 0);  // since maxMonth is 1-based, we use the 0th day of the next month to get the last day of the month

    return {
        "metric_date__gte": moment(startDate).format("YYYY-MM-DD"),
        "metric_date__lte": moment(endDate).format("YYYY-MM-DD")
    }

}

export const convertParamsToMetricDate = (params, dayOfMonthMaxDate) => {
    return {
        ...params,
        ...monthYearToMetricDate(params.month, params.year, dayOfMonthMaxDate)
    }
}

export const preProcessParams = (params) => {
    const newParams = {
        ...params,
    }

    // delete "month" and "year" parameters if they exist
    // because we don't want to send them to the backend,
    // we want to send "transaction_date__gte" and "transaction_date__lte" instead
    if (newParams.month) {
        delete newParams.month
    }
    if (newParams.year) {
        delete newParams.year
    }

    // remove empty arrays or empty strings or null values
    Object.keys(newParams).forEach(key => {
        if (Array.isArray(newParams[key]) && newParams[key].length === 0) {
            delete newParams[key]
        }
        if (newParams[key] === "") {
            delete newParams[key]
        }
        if (newParams[key] === null) {
            delete newParams[key]
        }
    });

    // convert columns if array to string
    if (newParams.columns && Array.isArray(newParams.columns)) {
        newParams.columns = newParams.columns.join(',')
    }
    // convert group_by if array to string
    if (newParams.group_by && Array.isArray(newParams.group_by)) {
        newParams.group_by = newParams.group_by.join(',')
    }

    return newParams
}

export const barGraphData = (rowData) => {
    return {
        // height: "20em",
        grid: {
            top: 45,
            left: 75
        },
        yAxis: {
            data: ["Target", "Last Month", "Last Year"].reverse(),
            axisLabel: {
                fontSize: 12,
                color: '#000'
            }
        },

        xAxis: {
            type: "value",
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            }
        },

        series: [
            //1st object is to create another bar to display another label inside.
            {
                data: [
                    // {value: rowData.actual_sales, itemStyle: { color: '#073e92'}}, // Current
                    { value: rowData.target_sales, itemStyle: { color: '#59a14f' }, currentVal: rowData.actual_sales }, // Target
                    { value: rowData.last_month_sales, itemStyle: { color: '#d3d3d3' }, currentVal: rowData.actual_sales }, // Last Month
                    { value: rowData.last_year_sales, itemStyle: { color: '#767f8b' }, currentVal: rowData.actual_sales } // Last Year
                ].reverse(),
                type: "bar",
                silent: true,
                barGap: '-100%',
                label: {
                    position: "insideLeft",
                    distance: 75,
                    show: true,
                    fontSize: 15,
                    fontWeight: 'bold',
                    formatter: function (params) {
                        const percentage = Math.round((params?.data?.currentVal / params.value) * 100) + "%"
                        return percentage
                    }
                },
            },
            {
                data: [
                    // {value: rowData.actual_sales, itemStyle: { color: '#073e92'}}, // Current
                    { value: rowData.target_sales, itemStyle: { color: '#59a14f' }, currentVal: rowData.actual_sales }, // Target
                    { value: rowData.last_month_sales, itemStyle: { color: '#d3d3d3' }, currentVal: rowData.actual_sales }, // Last Month
                    { value: rowData.last_year_sales, itemStyle: { color: '#767f8b' }, currentVal: rowData.actual_sales } // Last Year
                ].reverse(),
                type: "bar",
                barGap: '-100%',
                silent: true,
                label: {
                    position: "right",
                    show: true,
                    fontSize: 15,
                    fontWeight: 'bold',
                    formatter: function (params) {
                        return abbreviateNumber(params.value, true);
                    }
                },
                markLine: {
                    silent: 'true',
                    lineStyle: {
                        type: 'solid',
                        width: 5,
                        color: 'black'
                    },
                    symbol: 'none',
                    data: [[
                        {
                            value: rowData.actual_sales, xAxis: rowData?.actual_sales, y: 30,
                            label: {
                                position: "start",
                                show: true,
                                fontSize: 15,
                                fontWeight: 'bold',
                                formatter: function (params) {
                                    return `Current (₱ ${abbreviateNumber(params?.value, true)})`
                                }
                            },
                        },
                        { value: rowData.actual_sales, xAxis: rowData?.actual_sales, y: '80%' },
                    ]],
                }
            }
        ]
    };
}

export const getInvertedPercentageColor = (data, isInverted) => {
    let color = '#000';
    if (isInverted) {
        if (data <= 100) {
            color = '#00b38a'
        } else {
            color = '#f2ac42'
        }
    } else {
        if (data >= 0 && data < 50) {
            color = '#ea324c'
        } else if (data >= 50 && data < 100) {
            color = '#f2ac42'
        } else {
            color = '#00b38a'
        }
    }
    return color
}

export const getDefaultPercentageColor = (data) => {
    let color = '#000';
    if (data <= 95) {
        color = '#ea324c'
    } else if (data > 95 && data < 100) {
        color = '#f2ac42'
    } else {
        color = '#00b38a'
    }
    return color

}

export const regroupDataBy = (data, groupBy, value) => {
    if (!data) {
        return {}
    }

    return data.reduce((acc, curr) => {
        const key = curr[groupBy];
        if (!acc[key]) {
            acc[key] = 0;
        }
        if (value) {
            acc[key] = curr[value];
        } else {
            acc[key] = curr;
        }
        return acc;
    }, {});
}

export const groupArrayBy = (data, groupBy) => {
    if (!data) {
        return {}
    }

    return data.reduce((acc, curr) => {
        const key = curr[groupBy];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
    }, {});
}


export const numberCellDisplay = (value, isOnMobile, noPrefix) => {
    const prefix = noPrefix ? '' : '₱';
    const conditionalStyles = convertNumberToLocaleString(value) !== 0 ? {} : { width: '100%', textAlign: 'center' }
    return <Box sx={{ whiteSpace: 'nowrap', ...conditionalStyles }}> {convertNumberToLocaleString(value) !== 0 ? prefix + displayNumber(value, isOnMobile) : '-'} </Box>
}

export const abbreviatedNumberCellDisplay = (value, isOnMobile, noPrefix, hasPercentPrefix) => {
    const prefix = noPrefix ? '' : '₱';
    const percentPrefix = hasPercentPrefix ? '%' : '';
    const conditionalStyles = convertNumberToLocaleString(value) !== 0 ? {} : { width: '100%', textAlign: 'center' }
    return <Box sx={{ whiteSpace: 'nowrap', ...conditionalStyles }}> {convertNumberToLocaleString(value) !== 0 ? prefix + topLineDisplayNumber(value, !isOnMobile && isOnMobile) + percentPrefix : '-'} </Box>
}

export const percentCellDisplay = (value) => {
    return <Box sx={{ whiteSpace: 'nowrap' }}> {convertToValidNumber(value)?.toFixed() + '%'} </Box>
}

//Getting the days present in a month.
//data: the params/filters selected
export const getDaysInMonth = (date) => {
    return moment(date, "YYYY-MM").daysInMonth();
}

export const getDaysDifference = (start, end) => {
    const dateOne = moment(start);
    const dateTwo = moment(end);

    //plus 1 since moment diff gives a day less. Needs to double check.
    return dateTwo.diff(dateOne, 'days') + 1
}

export const getPast12months = (month, year) => {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    // Validate input
    if (year !== null && month !== null && !isNaN(parseInt(month)) && month >= 1 && month <= 12 && !isNaN(parseInt(year))) {
        let now = new Date(`${year}-${(month).toString().padStart(2, '0')}-01`);

        // Validate if the date is valid
        if (!isNaN(now.getTime())) {
            let paramsMonth = [];
            let paramsYear = [];
            let monthYear = [];

            for (let i = 0; i <= 11; i++) {
                let past = new Date(now);
                past.setMonth(now.getMonth() - i);
                paramsMonth.push(months[past.getMonth()]);
                paramsYear.push(past.getFullYear());
                monthYear.push(months[past.getMonth()] + '|' + past.getFullYear());
            }

            return {
                month: paramsMonth,
                year: [...new Set(paramsYear)], // remove duplicates in year
                monthYear: monthYear
            };
        } else {
            // Invalid date, return a default value or an object indicating an invalid state
            console.error("Invalid date");
            return { monthYear: [] }
        }
    } else {
        console.error(`Invalid month [${month}] and/or year [${year}]`);
        return { monthYear: [] }
    }
};

export const getPast12monthsL3 = (month, year, maxDate) => {
    // Validate input
    if (year !== null && month !== null && !isNaN(parseInt(month)) && month >= 1 && month <= 12 && !isNaN(parseInt(year))) {
        const now = new Date(`${year}-${(month).toString().padStart(2, '0')}-01`);

        // Validate if the date is valid
        if (!isNaN(now.getTime())) {
            let monthYear = [];

            for (let i = 0; i <= 11; i++) {
                const past = new Date(now);
                past.setMonth(now.getMonth() - i);
                monthYear.push(past.getFullYear() + '-' + `${(past.getMonth() + 1).toString().padStart(2, '0')}`); // to make the format YYYY-MM; formerly MMM|YYYY
            }

            // const previousMonth = moment([year, month - 1]).subtract(1, 'months').endOf('month');
            const minDate = moment([year, month - 1]).subtract(12, 'months').startOf('month'); // Set to the first day of the month
            const minDateString = moment(minDate).format("YYYY-MM-DD");
            // const maxDateString = moment(previousMonth).format("YYYY-MM-DD");
            const maxDateString = moment(maxDate).endOf('month').format("YYYY-MM-DD");

            return {
                header_tran_date__gte: minDateString,
                header_tran_date__lte: maxDateString,
                monthYear: monthYear
            };
        } else {
            // Invalid date, return a default value or an object indicating an invalid state
            console.error("Invalid date");
            return { monthYear: [] }
        }
    } else {
        console.error(`Invalid month [${month}] and/or year [${year}]`);
        return { monthYear: [] }
    }
};

export function toTitleCase(text) {
    return text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function simplifyNumberValue(value) {
    const abbreviations = ["", "K", "M", "B", "T"];

    let index = 0;
    while (value >= 1000 && index < abbreviations.length - 1) {
        value /= 1000;
        index++;
    }

    value = Math.round(value * 100) / 100;

    const simplifiedValue = `₱${value.toString()} ${abbreviations[index]}`;

    return simplifiedValue;
}

export function checkIfCompletePeriod(params, maxDate, isQoQ) {
    if (!params || !params.month || !params.year || !maxDate) {
        return false;
    }

    const selectedMonth = params.month[params.month.length - 1] - 1; // May (0-indexed for moment.js)
    const selectedYear = params.year;
    const maxDateMoment = moment(maxDate);

    if (isQoQ) {
        const endOfSelectedQuarter = moment([selectedYear, selectedMonth]).endOf('quarter');
        return maxDateMoment.isSameOrAfter(endOfSelectedQuarter);
    } else { // default behaviour for MTD & YTD periods of observation; both work similarly in its approach to check if the currently selected month is complete
        const endOfSelectedMonth = moment([selectedYear, selectedMonth]).endOf('month');
        return maxDateMoment.isSameOrAfter(endOfSelectedMonth);
    }
}

// basically simplified form of util NumericValueDisplay for formatting the values that will be plugged into getting the max length of the metrics
const getLengthFormatValue = (value, prefix = '', postfix = '', isAbbreviated = false) => {
    if (value === 'N/A') {
        return value;
    }

    if (!value || !isFinite(value) || isNaN(value)) {
        return '-'
    }

    let display;

    if (value < 1 && Math.sign(value) !== -1) {
        display = decimalDisplayNumber(value); // Format value to 3 decimal places if less than 1
    } else if (value.toString().includes('-0.000')) {
        display = 0; // Replace negative zero with zero
    } else {
        const numberDisplay = displayNumber(value);
        const numberDisplayTop = topLineDisplayNumber(value);
        display = isAbbreviated ? numberDisplayTop : numberDisplay;
    }

    const formattedValue = `${prefix}${display}${postfix}`;
    return formattedValue.length;
};

export function getMaxMetricTotalLength(data, key, metric_column) {
    const maxLength = data?.filter(row => row[key] !== null && row[key] !== undefined)?.reduce((maxLength, row) => {
        const value = row[key] ? row[key] : 0;
        const hasNoPrefixPostfix = ['actual_transactions', 'actual_units_sold', 'actual_units_sold_per_txn'];
        const length = (() => {
            if (hasNoPrefixPostfix.includes(metric_column)) {
                return getLengthFormatValue(value, '', '', true);
            } else if (metric_column === 'actual_catchment_rate' || metric_column === 'actual_conversion_rate') {
                return getLengthFormatValue(value, '', '%', true);
            } else {
                return getLengthFormatValue(value, '₱', '', true);
            }
        })();
        return Math.max(maxLength, length);
    }, 0);
    return maxLength;
};
