import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from "react-query";
import ApiClient from "./index";
import {useMemo} from "react";

export function useTableList(filters, useQueryOptions) {
    return useQuery(['data', 'table', 'list', filters], () => {
        return ApiClient().get("/data/table/", {
            params: {
                ...filters
            }
        }).then(response => {
            return response.data
        })
    }, {
        staleTime: 15 * 60 * 1000, // 15 minutes
        cacheTime: 30 * 60 * 1000, // 30 minutes
        ...useQueryOptions
    })
}

export function useUserList(filters, useQueryOptions) {
    return useQuery(['user', filters], () => {
        return ApiClient().get("/user/", {
            params: {
                ...filters
            }
        }).then(response => {
            return response.data
        })
    }, {
        staleTime: 15 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
        ...useQueryOptions
    })
}

export function useSystemLogs(filters, useQueryOptions) {
    return useQuery(['system', filters], () => {
        return ApiClient().get("/system/logs/", {
            params: {
                ...filters
            }
        }).then(response => {
            return response.data;
        })
    }, {
        staleTime: 15 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
        ...useQueryOptions
    })
}

export function useSystemLogsDailyCount(filters, useQueryOptions) {
    return useQuery(['daily_counts', filters], () => {
        return ApiClient().get("/system/logs/daily_count/", {
            params: {
                ...filters
            }
        }).then(response => {
            return response.data;
        })
    }, {
        staleTime: 15 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
        ...useQueryOptions
    })
}


export function useGroupList(filters, useQueryOptions) {
    return useQuery(['group', filters], () => {
        return ApiClient().get("/group/", {
            params: {
                ...filters
            }
        }).then(response => {
            return response.data
        })
    }, {
        staleTime: 15 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
        ...useQueryOptions
    })
}

export function usePermissionList(filters, useQueryOptions) {
    return useQuery(['permission', filters], () => {
        return ApiClient().get("/permission/", {
            params: {
                ...filters
            }
        }).then(response => {
            return response.data
        })
    }, {
        staleTime: 15 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
        ...useQueryOptions
    })
}

export function useModuleList(filters, useQueryOptions) {
    return useQuery(['module', filters], () => {
        return ApiClient().get("/module/", {
            params: {
                ...filters
            }
        }).then(response => {
            return response.data
        })
    }, {
        staleTime: 15 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
        ...useQueryOptions
    })
}

export function useTableDetail(tableId, useQueryOptions) {
    return useQuery(['data', 'table', tableId, 'detail'], () => {
        return ApiClient().get(`/data/table/${tableId}/`).then(response => {
            return response.data
        })
    }, {
        staleTime: 15 * 60 * 1000, // 15 minutes
        cacheTime: 30 * 60 * 1000, // 30 minutes
        ...useQueryOptions
    })
}

export function useTableDataFilterColumns(tableId, useQueryOptions) {
    const {data, isLoading} = useTableDetail(tableId, useQueryOptions)

    return {
        isLoading,
        data: data?.columns?.filter(column => column.filterable)
    }
}

export function useFilterValues(tableId, columnNames, filters, useQueryOptions) {
    const columnListString = Array.isArray(columnNames) ? columnNames.join(',') : columnNames;

    return useQuery(['data', 'table', 'filter-values', tableId, columnNames, filters], () => {
        return ApiClient().get(`/data/table/${tableId}/filter_values/`, {
            params: {
                columns: columnListString,
                ...filters
            }
        }).then(response => {
            return response.data
        })
    }, {
        cacheTime: 15 * 60 * 1000, // 15 minutes
        staleTime: 15 * 60 * 1000, // 15 minutes
        ...useQueryOptions
    })
}


export function useChartList(filters, useQueryOptions) {
    return useQuery(['dashboard', 'chart', 'list', filters], () => {
        return ApiClient().get("/dashboard/chart/", {
            params: {
                ...filters
            }
        }).then(response => {
            return response.data
        })
    }, {
        staleTime: 15 * 60 * 1000, // 15 minutes
        cacheTime: 30 * 60 * 1000, // 30 minutes
        ...useQueryOptions
    })
}

export function useChartDetail(chartId, useQueryOptions) {
    return useQuery(['dashboard', 'chart', chartId], () => {
        return ApiClient().get(`/dashboard/chart/${chartId}/`).then(response => {
            return response.data
        })
    }, {
        staleTime: 15 * 60 * 1000, // 15 minutes
        cacheTime: 30 * 60 * 1000, // 30 minutes
        ...useQueryOptions
    })
}


export function useSaveChart(chartId) {
    const queryClient = useQueryClient();

    const mutation = useMutation((chartData) => {
        if (chartId) {
            return ApiClient().patch(`/dashboard/chart/${chartId}/`, chartData).then(response => {
                return response.data
            })
        } else {
            return ApiClient().post(`/dashboard/chart/`, chartData).then(response => {
                return response.data
            })
        }
    },{
        onSuccess: data => {
            // invalidate the list so that react query will refetch the list
            queryClient.invalidateQueries(['dashboard', 'chart'])

            // if userChartDetail is being used, set the data directly, so it doesn't need to be refetched
            queryClient.setQueryData(['dashboard', 'chart', data.id], data)
        }
    });

    return {
        saveChart: mutation.mutate,
        saveChartAsync: mutation.mutateAsync,
        // resetStatus: mutation.reset,
        ...mutation
    }
}


// clone or copy chart to user's account
export function useCloneChart(originalChartId) {
    const queryClient = useQueryClient();
    const mutation = useMutation(() => {
        return ApiClient().post(`/dashboard/chart/${originalChartId}/copy/`).then(response => {
            return response.data
        })
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries(['dashboard', 'chart'])
            queryClient.setQueryData(['dashboard', 'chart', data.id], data)
        }
    })
}


// Dashboard hooks
export function useDashboardList(filters, useQueryOptions) {
    return useQuery(['dashboard', 'list', filters], () => {
        return ApiClient().get("/dashboard/", {
            params: {
                ...filters
            }
        }).then(response => {
            return response.data
        })
    })
}

export function useDashboardDetail(dashboardId, useQueryOptions) {
    return useQuery(['dashboard', dashboardId], () => {
        return ApiClient().get(`/dashboard/${dashboardId}/`).then(response => {
            return response.data
        })
    })
}

export function useSaveDashboard(dashboardId) {
    const queryClient = useQueryClient();

    const mutation = useMutation((dashboardData) => {
        if (dashboardId) {
            return ApiClient().patch(`/dashboard/${dashboardId}/`, dashboardData).then(response => {
                return response.data
            })
        } else {
            return ApiClient().post(`/dashboard/`, dashboardData).then(response => {
                return response.data
            })
        }
    }, {
        onSuccess: data => {
            // invalidate the list so that react query will refetch the list
            queryClient.invalidateQueries(['dashboard', 'list'])

            // if userChartDetail is being used, set the data directly, so it doesn't need to be refetched
            queryClient.setQueryData(['dashboard', data.id], data)
        }
    });

    return {
        saveDashboard: mutation.mutate,
        saveDashboardAsync: mutation.mutateAsync,
        ...mutation
    }
}

export function useSchemaList() {
    return useQuery(['schema','list'], () =>
        ApiClient().get('data/schemas').then(res => {
            return res.data
    }),
    {
        refetchOnWindowFocus: false,
        staleTime: 600000
    });
}

function parseIntWithDefault(value, defaultValue) {
    const parsed = parseInt(value)
    if (isNaN(parsed)) {
        return defaultValue
    } else {
        return parsed
    }
}


export function useFetchAll(fetchFunction, queryKey, queryOptions) {
    // TODO: handle errors
    const getNextPageParam = (lastPage, pages) => {
        const {count, offset, limit} = lastPage;
        const effectiveOffset = parseIntWithDefault(offset, 0);
        const effectiveLimit = parseIntWithDefault(limit, 100);
        if (count > effectiveOffset + effectiveLimit) {
            return effectiveOffset + effectiveLimit;
        } else {
            return undefined;
        }
    }

    const getPreviousPageParam = (firstPage, pages) => {
        const {offset, limit} = firstPage;
        const effectiveOffset = parseIntWithDefault(offset, 0);
        const effectiveLimit = parseIntWithDefault(limit, 100);

        if (effectiveOffset > 0) {
            return effectiveOffset - effectiveLimit;
        } else {
            return undefined;
        }
    }


    const {isLoading, data, fetchNextPage, hasNextPage} = useInfiniteQuery(
        queryKey,
        fetchFunction,
        {
            getNextPageParam: getNextPageParam,
            getPreviousPageParam: getPreviousPageParam,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            cacheTime: 15 * 60 * 1000, // 30 minutes
            staleTime: 15 * 60 * 1000, // 15 minutes
            ...queryOptions,
            onSuccess: (data) => {
                const lastPage = data.pages[data.pages.length - 1];
                if (getNextPageParam(lastPage)) {
                    fetchNextPage();
                } else {
                    // finally call the on success function if it exists
                    if (queryOptions?.onSuccess) {
                        queryOptions.onSuccess(data);
                    }
                }
            }
        }
    );

    const allData = useMemo(() => {
        if (!data || isLoading || hasNextPage) {
            return undefined;
        }
        return data.pages.map(page => page.data).flat();
    }, [data, isLoading, hasNextPage]);

    const progressData = useMemo(() => {
        if (!data || !data.pages) {
            return undefined;
        }

        const lastPage = data.pages[data.pages.length - 1];
        const {count, offset, limit} = lastPage;
        const effectiveOffset = parseIntWithDefault(offset, 0);
        const effectiveLimit = parseIntWithDefault(limit, 100);
        const downloaded = effectiveOffset + effectiveLimit
        const progress = downloaded / count;
        return {
            count,
            downloaded,
            progress
        };
    }, [data])

    return {
        isLoading: isLoading || hasNextPage,
        data: allData,
        progressData
    }
}