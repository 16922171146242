import React from "react";
import {WindowSizeContext} from "../../../context/WindowSizeContext";
import {Grid, Typography} from "@mui/material";
import CollapsibleComponent from "../../CollapsibleComponent";


export default function ResponsiveSectionHeader({title, selectorsOrFilters, isExpanded, setIsExpanded}) {
    const windowSize = React.useContext(WindowSizeContext)

    return (
        <Grid container columns={12} alignItems="center" p={.5}>
            {
                windowSize?.isOnMobile ? <>
                    <Grid item xs={12} display="flex" justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h6" ml={1}>
                            {title}
                        </Typography>
                        <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded}/>
                    </Grid>
                    <Grid item xs={12} m={1}>
                        {selectorsOrFilters}
                    </Grid>
                </> : <>
                    <Grid item xs={8}>
                        <Typography variant='h6' ml={2}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {selectorsOrFilters}
                    </Grid>

                    <Grid item xs={1}>
                        <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded}/>
                    </Grid>
                </>
            }
        </Grid>
    )
}