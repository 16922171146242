import React from 'react';
import {Box} from "@mui/material";
import SubRowExpander from "./SubRowExpander";


export default function CellWithSubRow({row, children, isLoadingSubRows=false}) {
    return (
        <Box sx={{
            fontWeight: row.depth === 0 ? 'bold' : 'regular',
            marginLeft: `${row.depth * 1}rem`,
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center'
        }}>
            {children}
            <SubRowExpander row={row} isLoadingSubRows={isLoadingSubRows}/>
        </Box>
    );
}