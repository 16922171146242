import React, { useEffect, useMemo, useState } from 'react';
import {  Accordion, AccordionSummary, AccordionDetails, FormControl, Select, MenuItem, Checkbox, InputLabel, TextField, InputAdornment,IconButton } from '@mui/material';
import { useDebounce } from 'use-debounce';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

const theme = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    '&.Mui-expanded': {
                        margin: 0,
                        minHeight: '.5rem !important',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    minHeight: '.5rem !important',					
                    borderBottom: '1px solid #A6A6A7',
                },
                content: {
                    padding: 0,
                    margin: 0,
                    alignItems: 'center',
                    '&.Mui-expanded': {				
                        margin:0,
                        minHeight: '.5rem !important',
                    },
                },
            },
        },
        MuiMenuItem: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        margin: 0,
                    }
                }
            },
        },
});

const NestedDropdownCheckbox = ({ options, label, onSelectionChange }) => {
    //selected values are children values of the last available level/depth.
    const [selected, setSelected] = useState([]); 
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

    useEffect(() => {
        const allLastChildren = options.map((option) => getDeepestNodes(option)).reduce((acc, val) => acc.concat(val), []);
        // make sure that the selected values are children values of the last available level/depth.
        setSelected((prevState) => {
            const newState = prevState.filter((item) => allLastChildren.map((child) => child.value).includes(item))
            if (newState.length === prevState.length) {
                return prevState
            }
            return [...newState]
        });
    }, [options]);

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selected);
        }
    }, [selected, onSelectionChange]);


    const getDeepestNodes = (option) => {
        if (option?.children) {
            return option.children.map((child) => getDeepestNodes(child)).reduce((acc, val) => acc.concat(val), []);
        }
        return [option];
    };
	
    const isOptionChecked = (option) => {
        if (option?.children) {
            const isChecked = option.children.every((child) => isOptionChecked(child));
            return isChecked
        } else {
            return selected.includes(option.value);
        }
    }

    const isOptionIndeterminate = (option) => {
        if (option?.children) {
            return option.children.some((child) => isOptionIndeterminate(child));
        } else {
            return selected.includes(option.value);
        }
    }

    const toggleOptionCheck = (option) => {
        const isChecked = isOptionChecked(option);
        const isIndeterminate = isOptionIndeterminate(option);
        const allLastChildren = getDeepestNodes(option);
        if (isChecked) {
            setSelected((prevState) => prevState.filter((item) => !allLastChildren.map((child) => child.value).includes(item)));
        } else if (isIndeterminate) {
            setSelected((prevState) => {
                const newSelected = [...prevState, ...allLastChildren.map((child) => child.value)];
                return newSelected.filter((item, index) => newSelected.indexOf(item) === index);  // remove duplicates
            })
        } else {
            setSelected((prevState) => [...prevState, ...allLastChildren.map((child) => child.value)]);
        }
    }

    const renderOptions = (options, depth=0) => {	
        // recursive call to the filter function(search), 
        // so filters can be applied to both parent and child.
        return options.filter((option) => option?.label?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) || (option?.children && renderOptions(option.children, depth + 1).length > 0)).map((option) => {
            const isChecked = isOptionChecked(option);
            const isIndeterminate = isOptionIndeterminate(option);

            if (option?.children) {
                //parent
                return < Accordion key={option.value} style={{ paddingLeft: `${depth > 0 ? 10 : 0}px`, display:"block" }} onChange={(e)=> {e.stopPropagation()} } disableRipple defaultExpanded >
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${option.value}-content`}
                        id={`${option.value}-header`}
                    >
                    <Checkbox
                        disableRipple
                        checked={isChecked} indeterminate={!isChecked && isIndeterminate}
                        onChange={(e)=> {e.stopPropagation();  toggleOptionCheck(option)}}
                        onClick={(e)=> e.stopPropagation()}
                        size="small"
                    />
                        { option.label }
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, margin: 0}}>
                    {renderOptions(option.children, depth + 1)}
                </AccordionDetails>
            </Accordion>
            } else {
            //children
                return <MenuItem key={option.value} onChange={(e)=> e.stopPropagation() } style={{ paddingLeft: `${depth > 0 ? 30 : 0}px` }} disableRipple>
                    <Checkbox
                        disableRipple
                        checked={isChecked}
                        onChange={(e)=>  {e.stopPropagation(); toggleOptionCheck(option) }}
                        size="small"
                    />
                        { option.label }
                    </MenuItem>
            }
        });
    };

    const renderClearButton = (value) => {
        if(value?.length > 0){
            return (
            <IconButton onClick= {()=>setSelected([])} sx={{mr:1}}>
                <ClearIcon sx={{fontSize:'1.2rem'}} />
            </IconButton>
            )
        }
        return null;
    }

    const getLastStringValue = (value) => {
        return value?.map(val => val.split("|").pop())      
    }

    return (				
        <ThemeProvider theme={theme}>
            <FormControl size="small" fullWidth>
                <InputLabel>{label}</InputLabel>
                    <Select multiple value={selected} label={label} 
                        onChange={(e)=> e.stopPropagation() } 					
                        onKeyDown={(e) => e.stopPropagation()}
                        renderValue={(selected) => getLastStringValue(selected).join(', ')}
                        sx={{
                            backgroundColor: '#fff',
                        }}
                        
                        MenuProps={{
                            sx: {
                                maxHeight: 500
                            },
                        }}
                        endAdornment={renderClearButton(selected)}
                        >
                            <MenuItem key="search" onChange={(e)=> e.stopPropagation() }>						
                                <TextField placeholder="Type to filter" variant="standard" 
                                    fullWidth 
                                    value={searchTerm}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onChange={(e) => { e.stopPropagation(); setSearchTerm(e.target.value)}}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                                    }}/>
                            </MenuItem>
                            { renderOptions(options) }
                    </Select>
            </FormControl>
        </ThemeProvider>
    );
};

export default NestedDropdownCheckbox;