import React from "react";
import CellWithSubRow from "../../../../../../common/components/DataTable/CellWithSubRow";
import NumericValueDisplay from "../../numericValueDisplay";
import {useCustomerLocationTableData} from "./hooks";
import TableComponentV2 from "../../../../../../common/components/TableComponentV2";
import {zoneOrderArray} from "../../../../TmpTSSSales/StorePerformance/constants";
import LoadingComponent from "../../../../../../common/components/LoadingComponent";
import Box from "@mui/material/Box";
import {useParams} from "react-router-dom";


export default function CustomerLocationTable() {
    const {business_unit} = useParams()

    const {
        data,
        isLoadingZoneStoreLookup,
        isLoadingBarangayMetaLookup,
        isLoadingStoreBarangayLookup,
        isLoadingMetricLookupByBarangayKey,
        isLoadingP6MMembersPerBarangay,
    } = useCustomerLocationTableData({
        filters: {
            pm_business_unit_desc_standardized: business_unit
        }
    })

    const zonePkSort = (a, b) => {
        const indexOfA = zoneOrderArray.indexOf(a.original?.pk);
        const indexOfB = zoneOrderArray.indexOf(b.original?.pk)

        if (indexOfB === -1 && indexOfB === -1) {
            return a.original?.pk?.toString().localeCompare(b.original?.pk.toString())
        }

        return indexOfA - indexOfB
    }

    const customerLocationTableColumns = React.useMemo(() => {
        return [
            {
                header: 'Zone / Store / Brgy.',
                accessorKey: 'pk',
                customStyle: {
                    lineHeight: "2.5em"
                },
                cell: ({row, getValue}) => {
                    const value = getValue();
                    return (
                        <CellWithSubRow row={row} isLoadingSubRows={isLoadingStoreBarangayLookup}>
                            {value}
                        </CellWithSubRow>
                    )
                },
                sortingFn: zonePkSort
            },
            {
                header: 'Population',
                accessorKey: 'population',
                cell: ({row, getValue}) => {
                    if (isLoadingBarangayMetaLookup) {
                        return <LoadingComponent size={"1rem"} sxClasses={{py: 0}}/>
                    }
                    return <Box sx={{marginX: 'auto', width: "4em", textWrap: "nowrap"}}>
                        <NumericValueDisplay value={getValue()} isAbbreviated={true}/>
                    </Box>
                },
            },
            {
                header: 'SMAC Members',
                accessorKey: 'smacMembers',
                cell: ({row, getValue}) => {
                    if (isLoadingMetricLookupByBarangayKey) {
                        return <LoadingComponent size={"1rem"} sxClasses={{py: 0}}/>
                    }
                    return <Box sx={{marginX: 'auto', width: "4em", textWrap: "nowrap"}}>
                        <NumericValueDisplay value={getValue()} isAbbreviated={true}/>
                    </Box>
                }
            },
            // {
            //     header: '%P3M Shoppers',
            //     accessorKey: 'p3mShoppers',
            //     cell: ({row, getValue}) => {
            //         return getValue()
            //     }
            // },
            // {
            //     header: '%P6M Shoppers',
            //     accessorKey: 'percP6mShoppers',
            //     cell: ({row, getValue}) => {
            //         if (isLoadingMetricLookupByBarangayKey || isLoadingP6MMembersPerBarangay ) {
            //             return <LoadingComponent size={"1rem"} sxClasses={{py: 0}}/>
            //         }
            //         return <NumericValueDisplay value={getValue() * 100} isAbbreviated={true} postfix={'%'}/>
            //     }
            // },
            // {
            //     header: '%P9M Shoppers',
            //     accessorKey: 'p9mShoppers',
            //     cell: ({row, getValue}) => {
            //         return getValue()
            //     }
            // },
            // {
            //     header: '%P12M Shoppers',
            //     accessorKey: 'p12mShoppers',
            //     cell: ({row, getValue}) => {
            //         return getValue()
            //     }
            // },
            {
                header: 'SMAC Sales',
                accessorKey: 'smacSales',
                cell: ({row, getValue}) => {
                    if (isLoadingMetricLookupByBarangayKey) {
                        return <LoadingComponent size={"1rem"} sxClasses={{py: 0}}/>
                    }
                    return <Box sx={{marginX: 'auto', width: "4.5em", textWrap: "nowrap"}}>
                        <NumericValueDisplay value={getValue()} isAbbreviated={true} prefix={'₱'}/>
                    </Box>
                }
            },
            {
                header: '% Sales',
                accessorKey: 'percSales',
                cell: ({row, getValue}) => {
                    if (isLoadingMetricLookupByBarangayKey) {
                        return <LoadingComponent size={"1rem"} sxClasses={{py: 0}}/>
                    }
                    return <Box sx={{marginX: 'auto', width: "3em", textWrap: "nowrap"}}>
                        <NumericValueDisplay value={getValue() * 100} isAbbreviated={true} postfix={'%'}/>
                    </Box>
                }
            },
            {
                header: '% Penetration',
                accessorKey: 'percPenetration',
                cell: ({row, getValue}) => {
                    if (isLoadingBarangayMetaLookup || isLoadingMetricLookupByBarangayKey) {
                        return <LoadingComponent size={"1rem"} sxClasses={{py: 0}}/>
                    }

                    return <Box sx={{marginX: 'auto', width: "3em", textWrap: "nowrap"}}>
                        <NumericValueDisplay value={getValue() * 100} isAbbreviated={true} postfix={'%'}/>
                    </Box>
                }
            }
        ]
    }, [isLoadingBarangayMetaLookup, isLoadingMetricLookupByBarangayKey])


    if (isLoadingZoneStoreLookup) {
        return <LoadingComponent/>
    }


    return (
        <TableComponentV2
            initialSort={{id: 'pk', asc: true}}
            columns={customerLocationTableColumns}
            data={data}
            tableId={'customerLocation'}
            isCompact={true}
            customTableHeaderStyle={{
                backgroundColor: '#caddeb'
            }}
            hasPagination={true}
        />
    )
}