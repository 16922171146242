import React, { useContext } from 'react';
import { Autocomplete, TextField, Select, FormControl, InputLabel, MenuItem, IconButton, InputAdornment as MUIInputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { WindowSizeContext } from '../context/WindowSizeContext';
import CircularProgress from '@mui/material/CircularProgress';

export default function ConditionalSelectComponent({
    id,
    labelId,
    options,
    value,
    label,
    onSelectChange,
    endAdornment,
    clearSelected,
    disableClearable,
    isLoading,
    isOptionEqualToValue,
    disabled,
    startAdornment,
}) {
    const windowSize = useContext(WindowSizeContext);

    const renderClearButton = () => {
        const valueHasArrayContent = Array.isArray(value) ? value.length > 0 : Boolean(value);
        if (value && valueHasArrayContent && endAdornment) {
            return (
                <IconButton onClick={clearSelected} sx={{ mr: 1 }}>
                    <ClearIcon sx={{ fontSize: '1.2rem' }} />
                </IconButton>
            );
        }
        return null;
    };

    const StartAdornment = startAdornment !== false ? (
        <MUIInputAdornment position='start' sx={{ marginLeft: 1, marginRight: 0 }}>
            <span style={{ color: '#000' }}>{startAdornment}</span>
        </MUIInputAdornment>
    ) : null;

    return (
        <>
            {!windowSize.isOnMobile ? (
                <Autocomplete
                    value={value}
                    onChange={onSelectChange}
                    id={id}
                    size="small"
                    disabled={disabled}
                    disableClearable={disableClearable}
                    options={options}
                    sx={{ width: "100%" }}
                    autoHighlight
                    isOptionEqualToValue={isOptionEqualToValue}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: StartAdornment,
                                endAdornment: (
                                    <>
                                        {isLoading && <CircularProgress size={15} />}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    getOptionDisabled={(option) => option.isDisabled || option.noData}
                />
            ) : (
                <FormControl variant="outlined" size="small" sx={{ width: "100%" }}>
                    <InputLabel id={labelId}>{label}</InputLabel>
                    <Select
                        disabled={disabled}
                        labelId={labelId}
                        id={id}
                        label={label}
                        value={value?.value || value || '' || null}
                        onChange={onSelectChange}
                        sx={{ backgroundColor: "white" }}
                        MenuProps={{
                            PaperProps: {
                                sx: { maxHeight: '30vh' }
                            }
                        }}
                        endAdornment={renderClearButton()}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value} disabled={option.isDisabled || option.noData}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </>
    );
}
