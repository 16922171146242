import React, { useMemo, useContext} from 'react';
import { getSelectedOption } from '../utils';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete'; //STARTS HERE!
import TextField from '@mui/material/TextField';
import { WindowSizeContext } from '../context/WindowSizeContext';
import {MONTH} from "../../Constants/general";

export default function FilterMonth({ onDropdownChange, filters, dateRangeData, dateRangeIsLoading, isSuccess }) {
    const windowSize = useContext(WindowSizeContext);

    const startAvailableMonth = isSuccess ? dateRangeData && moment(dateRangeData.from).month() + 1 : null;
    const startAvailableYear = isSuccess ? dateRangeData && moment(dateRangeData.from).year() : null;
    const latestAvailableMonth = isSuccess ? dateRangeData && moment(dateRangeData.to).month() + 1 : null;
    const latestAvailableYear = isSuccess ? dateRangeData && moment(dateRangeData.to).year() : null;

    const monthOptions = MONTH.map(obj => {
        if ((obj.value < startAvailableMonth && filters?.year === startAvailableYear) || (obj.value > latestAvailableMonth && filters?.year === latestAvailableYear)) {
            return {
                ...obj,
                noData: true
            }
        }
        return obj
    }).map(option => {
        // disable selected months in the middle of range selection
        if (filters?.month?.length >= 3) {
            // remove min and max month values, they should always be enabled
            const selectedMonthValues = filters?.month?.filter(month => month !== Math.min(...filters?.month) && month !== Math.max(...filters?.month))
            return {
                ...option,
                isDisabled: selectedMonthValues?.includes(option.value) ? true : option.isDisabled
            }
        }
        // console.log(JSON.stringify(option));
        return option
    })

    const defaultOptionValue = (monthOptions && monthOptions.length > 0) ? monthOptions.filter(option => option.value === latestAvailableMonth)?.[0]?.value : null


    const selectedMonthOption = useMemo(() => {
        if (!monthOptions || monthOptions.length === 0) {
            return null
        }
        const option = getSelectedOption(filters?.month, monthOptions)
        if (option) {
            const NoDataInOption = option.find(opt => opt?.noData === true)
            if (NoDataInOption) {
                onDropdownChange([defaultOptionValue], 'month')
                return null
            } else {
                return option
            }
        }
        if (defaultOptionValue) {
            onDropdownChange([defaultOptionValue], 'month')
            return null
        }

    }, [defaultOptionValue, monthOptions,])

    const value = selectedMonthOption ? getSelectedOption(selectedMonthOption?.[0]?.value, monthOptions) : null;

    return (
        <Autocomplete
            fontFamily="Henry Sans"
            value={value || null}
            onChange={(event, newValue) => {
                if (newValue) {
                    onDropdownChange([newValue?.value], 'month');
                }
            }}
            loading={dateRangeIsLoading}
            disableClearable
            disableInteractive
            id="filter_month_options"
            size="small"
            options={monthOptions}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Month" onKeyDown={windowSize.isOnMobile ? (e) => e.preventDefault(): null} />}
            getOptionDisabled={(option) => option.isDisabled || option.noData}

        />
    );
}